<template>
  <div>
    <!--    筛选条件-->
    <bonus-search :isType="searchCondition.type" @doSearch="doSearch"/>
    <!--    列表-->
    <bonus-list ref="refBonusTables" :search-condition="searchCondition" @editBonus="editBonus" @editDept="editDept"/>
    <!--    指定组操作-->
    <BonusEdit :savedMember="savedMember" :id="currentId" :visible="dialogVisible" @close="closeDialog"/>
  </div>
</template>

<script>
import BonusSearch from './component/BonusSearch.vue'
// import BonusTable from './component/BonusTable'
import BonusHandle from './component/BonusHandle.vue'
import BonusEdit from "./component/BonusEdit.vue";
import BonusList from "./component/BonusList.vue";

export default {
  name: "BonusIndex",
  components: {
    BonusList,
    BonusEdit,
    BonusSearch, BonusHandle
  },
  data() {
    return {
      searchCondition: {group_id: null, group_ids: [], ym: null, groups: [], status: null, type: 1},
      dialogVisible: false,
      currentId: null,
    }
  },

  methods: {
    savedMember() {
      this.$refs.refBonusTables.initList()
    },
    doSearch(val) {
      this.searchCondition = {...val, type: 1}
      // this.$refs.refBonusTables.initList()
    },
    closeDialog() {
      this.dialogVisible = false
      if (this.$refs.refBonusTables)
        this.$refs.refBonusTables.initList()
    },
    editBonus(val) {
      this.currentId = val.bill_id
      this.dialogVisible = true
    },
    editDept(val) {
      this.currentId = val.id
      this.dialogVisible = true
    },
  }
}
</script>

<style scoped>

</style>
