<template>
  <div>
    <el-row>
      <el-col>
        <el-button v-if="userPermissions.indexOf('pa_kpi_share__add')>-1" icon='el-icon-plus' type='primary'
                   @click='showAddMember=!showAddMember'>新增成员
        </el-button>
        <!--        -->
        <el-button v-if="userPermissions.indexOf('pa_kpi_share__init')>-1" icon='el-icon-refresh' @click='handleInit'>
          初始化成员
        </el-button>
        <el-button style="float: right" :type="`${unsaved?'success':'default'}`" @click="saveConfirm('save')"
                   :disabled='disabledSave'
                   v-if="userPermissions.indexOf('pa_kpi_share__set_ratio') !== -1">保存比例
        </el-button>
      </el-col>
    </el-row>
    <CreateMember @saved="savedMember" :currentBonusBill="currentBonusBill" :visible.sync='showAddMember'/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import CreateMember from './CreateMember.vue'

export default {
  name: 'BonusHandle',
  components: {CreateMember},
  computed: {
    ...mapGetters(['userPermissions', 'buttonType']),
    disabledSave() {
      // 不能保存的条件：已保存或者状态为：人资已审核
      return this.bonus_bill.status === 3 || this.currentDataList.length === 0
    }
  },
  props: {
    group_id: {
      type: String,
      default() {
        return undefined
      }
    },
    ym: {
      type: String,
      default() {
        return undefined
      }
    },
    currentBonusBill: {
      type: Object,
      default() {
        return {}
      }
    },
    currentDataList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  watch: {
    currentBonusBill: {
      deep: true,
      handler(val) {
        this.bonus_bill = { ...val }
      }
    },
    currentDataList: {
      deep: true,
      handler(val) {
        this.dataList = val
        this.unsaved = true
      }
    }

  },
  data() {
    return {
      unsaved: false,
      showEditIcon: true,
      bonus_bill: {},
      dataList: [],
      showAddMember: false
    }
  },
  methods: {
    //总监审核
    canCheckFirst() {
      return this.bonus_bill && this.bonus_bill.status === 1
    },
    //人资审核
    canCheckSecond() {
      return this.bonus_bill && this.bonus_bill.status === 2
    },
    checkStatus() {
      if (this.bonus_bill.status === 3)
        return { type: 'success', label: '已完结' }
      else if (this.bonus_bill.status === 2) {
        return { type: 'warning', label: '待人资审核' }
      } else if (this.bonus_bill.status === 1) {
        return { type: 'warning', label: '待总监审核' }
      } else {
        return { type: 'info', label: '待提交总监审核' }
      }
    },
    //确认操作
    saveConfirm(mode) {
      let modeMsg = ''
      if (mode === 'save') {
        modeMsg = '保存'
      }

      let msg = `确认${modeMsg}【当前分组】【${this.currentBonusBill.year}-${this.currentBonusBill.date_value}】奖金分配明细, 是否继续?`

      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (mode === 'save') {
          this.commitSave()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })

    },
    //确认-提交保存信息
    async commitSave() {
      this.commitLoading = true
      let members = this.dataList.map((item) => {
        let data = {
          id: item.id,
          ratio: item.ratio
        }

        return data
      })

      let postData = {
        prize_id: this.bonus_bill.id,
        members: members
      }
      let info = await this.$api.saveSetShare(postData)
      this.commitLoading = false
      if (info) {
        this.$notify.success('保存成功')
      }
      this.unsaved = false
    },




    saved() {
      this.$emit('saved')
    },
    async commitBill() {
      //提交待总监审核
      let postData = {
        id: this.bonus_bill.id
      }
      await this.$api.commitBonusBill(postData)
      this.saved()
    },
    savedMember() {
      this.$emit('savedMember')
    },
    commitToNext() {
      //
      this.$confirm(`此操作将确认【提交-总监审核】, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '再想想',
        type: 'warning'
      }).then(() => {
        this.commitBill()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消【提交-总监审核】'
        })
      })
    },
    handleInit() {
      this.$confirm(`此操作将确认【初始化】, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '再想想',
        type: 'warning'
      }).then(async () => {
        let info = await this.$api.initDeptBonus({id: this.currentBonusBill.id})
        if (info)
          this.$message({
            type: 'success',
            message: '初始化成功'
          })
        this.savedMember()
      })
    }
  }
}
</script>

<style scoped>

.status-tag {
  margin-right: 10px;
}
</style>
