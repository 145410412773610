<template>
  <div>
    <el-dialog :title='dialogTitle' v-bind='$attrs' width='50%' @close="close" @open='onOpen' v-on='$listeners '
               append-to-body>
      <el-row :gutter='15'>
        <el-col :span='14'>
          <div class='dpt'>
            <div>
              <el-form>
                <el-form-item label='选择部门'>
                  <!--                  <DeptCascader ref='refDeptCascaderScope' :show-first-group='false'-->
                  <!--                                @selectedDept='selectDept' />-->
                  <EcpDeptSelect class='select-custom' ref='ecp_dept'
                                 @handleSelect='selectDept'/>
                </el-form-item>
                <el-form-item label='部门成员'>
                  <div style='display: block;margin-left: 10px'>
                    <el-checkbox v-model='checkAll' @change='handleCheckAllChange'
                                 style='float: left'>
                      全选({{ checkedMembers.length }}/{{ members.length }})
                    </el-checkbox>
                  </div>
                  <el-row>
                    <el-col :span='24'>
                      <div style='display: block; margin-top: 6px'>
                        <el-checkbox-group v-model='checkedMembers' @change='handleCheckedCitiesChange'>
                          <el-checkbox v-for='member in members' :label='member' :key='member.userid' size='large'
                                       border>
                            <span>{{ member.name }} <span>【岗位：{{ member.jobnumber || '暂无' }}】</span></span>
                            <span style='font-weight: lighter'> /
                        <span v-if="member.is_leave==='Y'" style='color: #F56C6C'>{{ member.is_leave_alias }}</span>
                        <span v-else>{{ member.is_leave_alias }}</span>
                      </span></el-checkbox>
                        </el-checkbox-group>
                      </div>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item style="position:absolute; bottom: 20px">
                  <el-button type='primary' @click='addToMembers'>添加成员</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>

        </el-col>
        <el-col :span='10'>
          <div class='member'>
            <!--            <el-form ref='elForm' :model='formData' :rules='rules' label-width='80px'>-->
            <!--              <el-form-item label='组名'>-->
            <!--                <DeptCascaderScope :type='`bonus_data`' ref='refDeptCascaderScope' :show-first-group='false'-->
            <!--                                   @selectedDept='selectCurrentDept' />-->
            <!--                &lt;!&ndash;              <span>{{ formData.dept_id }}</span>&ndash;&gt;-->
            <!--                &lt;!&ndash;              <span>{{ formData.group_name }}</span>&ndash;&gt;-->
            <!--              </el-form-item>-->
            <!--              <el-form-item label='月份'>-->
            <!--                <el-date-picker-->
            <!--                  v-model='formData.ym'-->
            <!--                  type='month'-->
            <!--                  value-format='yyyy-MM'-->
            <!--                  format='yyyy年MM月'-->
            <!--                  placeholder='选择月度' style='width: 140px'>-->
            <!--                </el-date-picker>-->
            <!--              </el-form-item>-->
            <!--              <el-form-item label='成员'>-->
            <template slot='label'>
              <span>成员({{ formData.members.length }})</span>
            </template>
            <el-tag
                v-for='tag in formData.members'
                :key='tag.userid'
                closable
                effect='plain'
                size='small'
                @close='handleClose(tag)'
            >
              {{ tag.name }}
            </el-tag>
            <div style="position:absolute; bottom: 10px;">
              <el-button type='primary' style="width: 336px" size="big" @click='handleConfirm' :disabled='loadingCommit'
                         :loading='loadingCommit'
                         v-if="userPermissions.indexOf('bonus_add_members')>-1">
                确认添加
              </el-button>
            </div>
          </div>

        </el-col>
        <!--              </el-form-item>-->
        <!--              <el-form-item>-->
        <!--                <el-button type='primary' @click='handleConfirm' :disabled='loadingCommit' :loading='loadingCommit'-->
        <!--                           v-if="userPermissions.indexOf('bonus_add_members')>-1">-->
        <!--                  确认添加-->
        <!--                </el-button>-->
        <!--              </el-form-item>-->
        <!--            </el-form>-->
        <!--          </div>-->

        <!--        </el-col>-->
      </el-row>
      <!--      <div slot="footer">-->
      <!--        <el-row>-->
      <!--          <el-col :span="24">-->
      <!--            <div style="display: inline;margin-left: 10px">-->

      <!--              <el-button @click="close">取消</el-button>-->
      <!--            </div>-->

      <!--          </el-col>-->
      <!--        </el-row>-->
      <!--      </div>-->
    </el-dialog>

  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import EcpDeptSelect from '@/pages/commerce/kol_mapper/EcpDeptSelect'

export default {
  name: 'CreateMember',
  computed: {
    ...mapGetters(['userPermissions']),
    dialogTitle() {
      return `奖金分配小组成员选择`
    }
  },
  components: {EcpDeptSelect},
  props: {
    currentBonusBill: {
      type: Object,
      default() {
        return {}
      }
    },
  },

  data() {
    return {
      loadingCommit: false,
      //userid:钉钉用户ID
      formData: { kpi_dept_id: '', group_name: '', members: []},
      rules: {
        kpi_dept_id: {required: true},
        userid: {required: true}
      },
      props: {
        label: 'name',
        children: 'zones'
      },
      count: 1,
      checkAll: false,
      checkedMembers: [],
      members: [],
    }
  },
  methods: {
    onOpen() {
      // this.getAInfo()
      this.checkedMembers = []
      this.members = []
      this.checkAll = false
      this.loadingCommit = false
      this.formData.members = this.currentBonusBill.items
      this.formData.kpi_dept_id = this.currentBonusBill.kpi_dept_id
      this.formData.prize_id = this.currentBonusBill.id
      this.formData.group_id = this.currentBonusBill.dept_id
    },
    close() {
      this.$refs['ecp_dept'].init(null)
      this.checkedMembers = []
      this.checkAll = false
      this.members = []
      this.loadingCommit = false
      this.$emit('update:visible', false)
    },
    encryptMobile(phone) {
      phone = '' + phone
      let reg = /(\d{3})\d{4}(\d{4})/ //正则表达式
      return phone.replace(reg, '$1****$2')
    },
    handleConfirm() {
      if (this.formData?.members && this.formData.length > 0) {
        this.$message.error('未选择成员')
        return false
      } else {
        this.loadingCommit = true
        this.saveInfo()
      }
    },
    handleClose(tag) {
      this.formData.members.splice(this.formData.members.indexOf(tag), 1)
    },
    async saveInfo() {
      // this.formData.members = this.checkedMembers
      // console.log(postData)
      if (this.formData.members.length === 0) {
        this.$notify.warning('请先添加成员')
        this.loadingCommit = false
        return false
      }
      await this.$api.savePaKpiPrizeItem(this.formData)
      this.loadingCommit = false
      this.$emit('saved')
      this.close()
    },

    // eslint-disable-next-line no-unused-vars
    selectDept(valArr, labelArr) {
      //加载小组成员
      // this.loadMembers({dpt_id:valArr})
      let kpi_dept_id = null
      // let group_name = null;

      if (valArr && valArr.length > 0) {
        let len = valArr.length
        // console.log(valArr[len - 1])
        kpi_dept_id = valArr[len - 1].dpt_id
        // group_name = labelArr[len - 1];
        //加载成员列表
        this.checkedMembers = []
        this.members = []
        this.isIndeterminate = true
        this.checkAll = false
        // console.log(kpi_dept_id)
        this.loadMembers({dpt_id: kpi_dept_id})
      }
    },
    selectCurrentDept(valArr, labelArr) {
      //加载小组成员
      // this.loadMembers({dpt_id:valArr})
      let kpi_dept_id = null
      let group_name = null

      if (valArr && valArr.length > 0) {
        let len = valArr.length
        kpi_dept_id = valArr[len - 1]
        group_name = labelArr[len - 1]
        this.formData.kpi_dept_id = kpi_dept_id
        this.formData.group_name = group_name
      }
    },
    async loadMembers(searchCondition) {
      this.members = []
      searchCondition.page_size = 50
      let {list, pages} = await this.$api.getDptUsers(searchCondition)
      this.members = [...this.members, ...list]
      searchCondition.page_size = pages.page_size
      searchCondition.current_page = pages.current_page
      for (let i = pages.offset + list.length; i < pages.total; i += pages.page_size) {
        // console.log(`i:${i}`)
        searchCondition.current_page += 1
        let {list} = await this.$api.getDptUsers(searchCondition)
        this.members = [...this.members, ...list]
      }

    },
    handleCheckAllChange(val) {
      this.checkedMembers = val ? [...this.members] : []
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.members.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.members.length
    },
    addToMembers() {
      //添加到成员(已添加，则跳过)
      this.checkedMembers.forEach((item) => {
        if (this.formData.members.findIndex(value => value.userid === item.userid) === -1) {
          this.formData.members.push(item)
        }
      })
    }
  }
}
</script>

<style scoped>
.dpt {
  min-height: 300px;
  //height: 500px;
  max-height: 800px;
  overflow-y: scroll;
  border: #ff3176 1px solid;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.member {
  min-height: 300px;
  //height: 500px;
  max-height: 800px;
  overflow-y: scroll;
  border-radius: 10px;
  border: #ff3176 1px solid;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
}
</style>

<style>
.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
