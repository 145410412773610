<template>
  <div>
    <el-row class="default-table">
      <el-col :span="24">
        <div v-loading="loadingStatus"
             :element-loading-text="loadingText"
             element-loading-spinner="el-icon-loading">
          <el-table
              :data="billItems"
              :span-method="objectSpanMethod"
              :summary-method="getSummaries"
              show-summary
              :cell-class-name="cellClassName"
              highlight-current-row
              style="width: 100%;" border>
            <el-table-column
                prop="group_name"
                label="组别"
                align="center"
                min-width="100">
              <template slot-scope="{row}">
                <el-link type="primary" @click="editBonus(row)" v-if="editGroup">{{ row.dept_name }}
                  <i class="el-icon-edit"></i></el-link>
                <span v-else>{{ row.dept_name }}</span>
              </template>
            </el-table-column>
            <el-table-column
                prop="prize"
                label="团队奖金包"
                align="center"
                min-width="110">
              <template slot-scope="scope">
                <span class="money">{{ valFormat(scope.row.prize * 1) }}</span>
              </template>
            </el-table-column>
            <el-table-column
                prop="index"
                label="序号"
                align="center"
                width="60" fixed="fixed">
              <template slot-scope="{$index}">
                {{ $index + 1 }}
              </template>
            </el-table-column>
            <el-table-column
                prop="position"
                label="岗位"
                align="center"
                min-width="100">
            </el-table-column>
            <el-table-column
                prop="name"
                label="姓名"
                align="center"
                min-width="100">
              <template slot-scope="scope">
                <span :title='`${scope.row.nickname}`'>{{ scope.row.name }}
                  <span style="color: #ff5a91;margin-left: 5px"
                        v-if="scope.row.nickname&&scope.row.nickname!==scope.row.name">({{
                    scope.row.nickname
                  }})</span></span>
              </template>
            </el-table-column>
            <el-table-column
                prop="ratio"
                label="比例"
                align="center"
                min-width="100">
              <editable-cell :show-input="row.editMode" slot-scope="{row,column}"
                             v-model="row[column.property]"
                             @change="editVal(row,'ratio')" :is-input-number="true"
                             v-if="userPermissions.indexOf('pa_kpi_share__set_ratio') > -1&&editMode">
                          <span slot="content">
                            <span class="ratio">{{ row[column.property] }}</span>
                            <i class="el-icon-edit default-edit-icon" v-if="showEditIcon"></i>
                          </span>
              </editable-cell>
              <template slot-scope="{row,column}" v-else>
                <span class="ratio">{{ row[column.property] }}</span>
              </template>
            </el-table-column>
            <el-table-column
                prop="amount"
                label="奖金"
                align="center"
                min-width="100">
              <template slot-scope="{row}">
              <span class="money">
                {{ valFormat((row.ratio * row.prize) / 100) }}
              </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import EditableCell from '@/components/EditableCell.vue'
import {numberFormat} from '@/utils/utils'
import {mapGetters} from 'vuex'

export default {
  name: 'BonusTable',
  components: {
    EditableCell,
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType']),
    loadingText() {
      return `奖金明细数据加载中...`
    },
    // 表格数据
    dataList() {
      let tableData = [];
      if (this.billItems && this.billItems.length > 0) {
        this.billItems.forEach((item) => {
          item.revenue_bonus = this.bonus_bill.revenue_bonus / 1
          item.trend_bonus = this.bonus_bill.trend_bonus / 1
          item.group_name = this.bonus_bill.group_name
          tableData.push(item)
        })
      }
      return tableData
    },
  },
  props: {
    searchCondition: {
      type: Object,
      default() {
        return {
          id: null
        }
      }
    },
    editMode: {
      type: Boolean,
      default() {
        return false;
      }
    },
    editGroup: {
      type: Boolean,
      default() {
        return false;
      }
    },
    canRevoke: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  watch: {
    searchCondition: {
      deep: true,
      handler() {
        // console.log('val',val)
        this.initList()
      }
    },
  },
  data() {
    return {
      loadingStatus: false,
      unsaved: false,
      showEditIcon: true,
      billItems: [],
      // dataList: [],
      bonus_bill: {},
      mergeFields: ['group_name', 'trend_bonus', 'revenue_bonus', 'prize'],//合并的字段
      spanObj: {},//合并的数据坐标Map
      positionObj: {},//合并的位置记录
      //start:鼠标右键菜单
      menuData: {
        visible: false,
        top: 0,
        left: 0,
      },
      currentRow: {},
      currentColumn: {}
    }
  },
  methods: {
    // 表格合并行
    // eslint-disable-next-line
    objectSpanMethod({row, column, rowIndex, columnIndex}) {
      let len = this.dataList.length;
      const fields = this.mergeFields
      if (fields.indexOf(column.property) > -1) {
        if (rowIndex % len === 0) {
          return {
            rowspan: len,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    getSummaries(param) {
      const {columns, data} = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 2) {
          sums[index] = '合计'
          return
        }
        if (['ratio', 'amount'].indexOf(column.property) > -1) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev * 1 + curr * 1
              } else {
                return prev
              }
            }, 0)
            sums[index] += ''
          } else {
            sums[index] = 'N/A'
          }
          let value = 0
          // 格式化数据
          switch (column.property) {
            case 'ratio':
              sums[index] = `${(sums[index])} %`
              break;
            case 'amount':
              data.forEach(row => {
                value = value + Number((row.ratio * row.prize) / 100)
              })
              sums[index] = `￥${this.valFormat(value)}`
              break;
            default:
              break
          }
        }
      })

      return sums
    },
    valFormat(value) {
      return numberFormat(value, 2, '.', ',', 'round')
    },
    editVal(row, colName) {
      if (colName === 'ratio') {
        row.amount = (row.trend_bonus * 1 + row.revenue_bonus * 1) * row.ratio / 100
      }
      this.unsaved = true
      this.$emit('editVal', this.dataList)// 通知父组件 修改后的表格数据
    },
    handleSearchCondition() {
      let condition = {}
      this.conditionStr = '';
      if (this.searchCondition.id) {
        condition.id = this.searchCondition.id
      }

      return condition
    },

    async initList() {
      this.loadingStatus = true
      this.billItems = []
      this.bonus_bill = {}
      let searchCondition = this.handleSearchCondition()
      if (searchCondition.id) {
        let {info} = await this.$api.getPaKpiPrizeInfo(searchCondition)

        this.bonus_bill = info
        this.billItems = info.items || [];
        // console.log('info', info)
        this.$emit('getInfo', this.bonus_bill)

      }
      this.loadingStatus = false

    },
    editBonus(row) {
      this.$emit('editBonus', row)
    },
    revokeBtn(row) {
      this.$confirm(`此操作将撤回[${row.group_name}/ ${this.searchCondition.ym}]的审核状态至[待总监审核], 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$emit('revokeEvent', {group_id: row.group_id, ym: this.searchCondition.ym})
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });

    },
    //修改成员分配信息是否显示问题
    async changeCheck(val) {
      if (['Y', 'N'].indexOf(val) > -1) {
        this.currentRow.is_show = val
        //标记隐藏
        await this.$api.changeBonusInfoShowStatus({id: this.currentRow.id, is_show: val})
      } else {
        //删除
        let info = await this.$api.delMemberFromBonusData(this.currentRow.id)
        if (info) {
          this.$notify.success('删除成功')
          let index = this.dataList.findIndex(value => value.id === this.currentRow.id)
          if (index > -1) {
            this.dataList.splice(index, 1)
          }
        }
      }
    },

    //单元格样式调整
    // eslint-disable-next-line
    cellClassName({row, column, rowIndex, columnIndex}) {
      //判定是否标记隐藏：
      if (column.property === 'name' && row.is_show === 'N') {
        return 'cell-uncheck'
      }
      return ''
    },
  }
}
</script>

<style scoped>
.bill_no {
  color: #fff;
  opacity: 0.5;
  margin-right: 10px;
}

</style>
