<template>
  <div>
    <el-row class='default-table'>
      <el-col :span='24'>
        <div v-loading='loadingStatus'
             :element-loading-text='loadingText'
             element-loading-spinner='el-icon-loading'>
          <ApeTable ref='apeTable'
                    :data='dataList'
                    :columns='[]'
                    :loading='loadingStatus'
                    :pagingData='pagingData'
                    @switchPaging='switchPaging'
                    highlight-current-row
                    border
                    @expand-change='expandChange'
                    :expand-row-keys='[expandRowKey]'
                    row-key='id'
          >
            <el-table-column type='expand' fixed='fixed'>
              <template slot-scope='props'>
                <BonusItemsTable @editBonus='editBonus' :group-data='props.row'
                                 :edit-group="userPermissions.indexOf('pa_kpi_share__info') > -1"/>
              </template>
            </el-table-column>
            <el-table-column
                prop='dept_name'
                label='组别'
                align='center'
                min-width='100'>
              <template slot-scope='{row}'>
                <el-link type='primary' @click='editDept(row)'
                         v-if="userPermissions.indexOf('pa_kpi_share__info') > -1" :title='`ID:${row.id}`'>
                  {{ row.up_dept_name }} / {{ row.dept_name }}<i
                    class='el-icon-edit'></i></el-link>
                <span v-else :title='`ID:${row.id}`'>  {{ row.up_dept_name }} / {{ row.dept_name }}</span>
              </template>
            </el-table-column>
            <el-table-column
                prop=''
                label='时间（年月）'
                align='center'
                min-width='100'>
              <template slot-scope='{row}'>
                {{ row.year }}年{{ row.date_value }}月
              </template>
            </el-table-column>
            <el-table-column
                prop='memberCount'
                label='成员数量'
                align='center'
                min-width='100'>
              <template slot-scope='{row}'>
                {{ row.items.length }}
              </template>
            </el-table-column>
            <el-table-column
                prop='prize'
                label='团队奖金'
                align='center'
                min-width='110'>
              <template slot-scope='scope'>
                <span class='money'>{{ valFormat(scope.row.prize) }}</span>
              </template>
            </el-table-column>
            <el-table-column
                prop='status'
                label='状态'
                align='center'
                min-width='100'>
              <template slot-scope='{row}'>
                <el-tag :type='calcStatusType(row.is_confirm_prize)'>
                  {{ row.is_confirm_prize === 0 ? '未确认' : '已确认' }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
                prop='status'
                label='操作'
                align='center'
                min-width='100'>
              <template slot-scope='{row}'>
                <el-button v-if="Number(row.is_confirm_prize) === 0 " v-has-authority="'pa_kpi_share__confirm'"
                           :type='calcStatusType(row.is_confirm_prize)' @click="revokeBtn(row)">
                  确认分配
                </el-button>
                <el-button v-else v-has-authority="'pa_kpi_share__revoke_confirm'"
                           :type='calcStatusType(row.is_confirm_prize)'
                           @click="revokeBtn(row)">
                  撤回确认
                </el-button>

              </template>
            </el-table-column>
          </ApeTable>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {numberFormat} from '@/utils/utils'
import ApeTable from '@/components/ApeTable'
import BonusItemsTable from './BonusItemsTable'
import {mapGetters} from "vuex";

export default {
  name: 'BonusList',
  components: {
    ApeTable, BonusItemsTable
  },
  props: {
    searchCondition: {
      type: Object,
      default() {
        return {}
      }
    },
    editMode: {
      type: Boolean,
      default() {
        return false
      }
    },
  },
  computed: {
    ...mapGetters(['userPermissions']),
    loadingText() {
      return `【${this.searchCondition.year}-${this.searchCondition.date_value}】的奖金数据加载中...`
    }
  },
  data() {
    return {
      loadingStatus: false,
      // 表格列表数据
      dataList: [],
      // 分页信息
      pagingData: {
        page_size: 10,
        current_page: 1,
        total: 0,
      },

      spanObj: {},//合并的数据坐标Map
      positionObj: {},//合并的位置记录
      expandRowKey: null
    }
  },
  watch: {
    searchCondition: {
      deep: true,
      handler() {
        // console.log('val',val)
        this.initList()
      }
    }
  },

  methods: {
    valFormat(value) {
      return numberFormat(value, 2, '.', ',', 'round')
    },
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.dept_id || this.searchCondition.dpt_id) {
        condition.dept_id = this.searchCondition.dept_id || this.searchCondition.dpt_id
      }
      if (this.searchCondition.type) {
        condition.type = this.searchCondition.type
      }
      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
      }
      // if (this.searchCondition.months) {
      //   condition.months = this.searchCondition.months
      // }
      if (this.searchCondition.depth) {
        condition.depth = this.searchCondition.depth
      }
      if (this.searchCondition.date_value) {
        condition.date_value = this.searchCondition.date_value
      }
      if (this.searchCondition.status != null && this.searchCondition.status !== '') {
        condition.status = this.searchCondition.status
      }
      if (this.searchCondition.quarter) {
        condition.quarter = this.searchCondition.quarter
      }
      return condition
    },
    async switchPaging() {
      this.expandRowKey = ''
      await this.initList()
    },
    /**
     * 记录当前展开页面
     * @param row
     */
    expandChange(row) {
      this.expandRowKey = row.id
    },
    async initList(type) {
      this.loadingStatus = true
      this.dataList = []
      let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, pagingInfo)
      if (searchCondition && searchCondition.year) {
        let {list, pages} = await this.$api.getShareList(searchCondition)
        let firstRowId = list[0] ? list[0]['id'] : ''
        let row = list.find((value) => value.id === this.expandRowKey)
        if (!row) {
          if (firstRowId) {
            this.expandRowKey = firstRowId
          }
        }
        this.$nextTick(() => {
          this.pagingData.total = pages.total
          this.pagingData.offset = pages.offset
          this.dataList = list
        })
      }
      this.loadingStatus = false

    },
    //编辑-明细
    editBonus(row) {
      this.$emit('editBonus', row)
    },
    // 编辑主单
    editDept(row) {
      this.$emit('editDept', row)
    },
    calcStatusType(status) {
      let type = ''
      switch (status) {
        case 0:
          type = 'success'
          break
        case 1:
          type = 'warning'
          break
        default:
          type = 'info'
          break
      }
      return type
    },
    revokeBtn(row) {
      let status = row.is_confirm_prize === 0 ? '确认' : '撤回确认'
      this.$confirm(`此操作将审核状态至[${status}], 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        this.revokeBill(row.id, row.is_confirm_prize)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    async revokeBill(id, type) {
      //0 未确认，需确认,1,已确认,需要撤回
      let postData = {
        id: id
      }
      let data = null
      if (Number(type) === 0) {
        data = await this.$api.confirmPaKpiPrize(postData)
      } else {
        data = await this.$api.revokePaKpiPrize(postData)
      }
      if (data) {
        this.$message.success(`${type === 0 ? '确认' : '撤回确认'}成功`)
      }
      await this.initList()
    },
  }
}
</script>

<style scoped>

</style>
